import {Action, Selector, State, StateContext} from '@ngxs/store';
import {catchError, switchMap, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {AnnouncementApiService, AnnouncementModel} from '@matchsource/api/announcements';

import {CreateAnnouncement, LoadLatestAnnouncement, UpdateAnnouncement} from './announcements.actions';
import {of} from 'rxjs';
import {AnnouncementTypes} from '@matchsource/models/announcement';

const defaultAnnouncement = (): AnnouncementModel => ({
  display: null,
  announcementTitle: '',
  isNew: true,
  type: AnnouncementTypes.BANNER,
  startDate: new Date().toISOString(),
  endDate: '',
});

export interface AnnouncementStateModel {
  loading: boolean;
  loaded: boolean;
  latest: AnnouncementModel;
}

@State<AnnouncementStateModel>({
  name: 'Announcements',
  defaults: {
    loading: false,
    loaded: false,
    latest: defaultAnnouncement(),
  },
})
@Injectable()
export class AnnouncementsState {
  constructor(private readonly api: AnnouncementApiService) {}

  @Selector([AnnouncementsState])
  static latest(state: AnnouncementStateModel): AnnouncementModel {
    return state.latest;
  }

  @Selector([AnnouncementsState])
  static loading(state: AnnouncementStateModel): boolean {
    return state.loading;
  }

  @Selector([AnnouncementsState])
  static loaded({loaded}: AnnouncementStateModel): boolean {
    return loaded;
  }

  @Action(LoadLatestAnnouncement)
  loadLatest(ctx: StateContext<AnnouncementStateModel>, {force}: LoadLatestAnnouncement) {
    const {loaded, loading} = ctx.getState();

    if (!force && (loaded || loading)) {
      return;
    }

    ctx.patchState({
      loading: true,
      loaded: false,
    });

    return this.api.getLatest().pipe(
      catchError(() => of(null)),
      tap(announcement => {
        ctx.patchState({
          latest: announcement || defaultAnnouncement(),
          loading: false,
          loaded: true,
        });
      })
    );
  }

  @Action(CreateAnnouncement)
  update(ctx: StateContext<AnnouncementStateModel>, {announcement}: CreateAnnouncement) {
    ctx.patchState({
      loading: true,
    });

    return this.api.create(announcement).pipe(switchMap(() => ctx.dispatch(new LoadLatestAnnouncement(true))));
  }

  @Action(UpdateAnnouncement)
  create(ctx: StateContext<AnnouncementStateModel>, {announcement}: UpdateAnnouncement) {
    ctx.patchState({
      loading: true,
    });

    return this.api.update(announcement).pipe(switchMap(() => ctx.dispatch(new LoadLatestAnnouncement(true))));
  }
}
