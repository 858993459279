import {AnnouncementModel} from '@matchsource/api/announcements';

export class LoadLatestAnnouncement {
  static readonly type = '[Announcements] Load Latest';

  constructor(public readonly force = false) {}
}

export class CreateAnnouncement {
  static readonly type = '[Announcements] Create';

  constructor(public readonly announcement: AnnouncementModel) {}
}

export class UpdateAnnouncement {
  static readonly type = '[Announcements] Update';

  constructor(public readonly announcement: AnnouncementModel) {}
}
