import {Injectable} from '@angular/core';
import {convertToString, Serializer} from '@matchsource/api-utils';
import {AnnouncementModel} from './announcement.model';
import {BannerAnnouncement} from '@matchsource/api-generated/common';
import {toDateTime} from '@matchsource/date';
import {AnnouncementTypes} from '@matchsource/models/announcement';

@Injectable({
  providedIn: 'root',
})
export class AnnouncementSerializerService implements Serializer<AnnouncementModel, BannerAnnouncement> {
  toDTO(model: AnnouncementModel): BannerAnnouncement {
    return {
      id: model.id ? Number.parseInt(model.id, 10) : undefined,
      announcementTitle: model.announcementTitle,
      published: !!model.display,
      type: model.type,
      startDate: model.startDate,
      endDate: toDateTime(model.endDate),
    };
  }

  fromDTO(input: BannerAnnouncement): AnnouncementModel {
    return {
      id: input.id ? convertToString(input.id) : undefined,
      announcementTitle: input.announcementTitle,
      display: input.published,
      isNew: !input.id,
      type: input.type as AnnouncementTypes,
      startDate: input.startDate,
      endDate: input.endDate,
    };
  }
}
