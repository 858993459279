import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Store} from '@ngxs/store';
import {AnnouncementsState} from './announcements.state';
import {AnnouncementModel} from '@matchsource/api/announcements';
import {CreateAnnouncement, LoadLatestAnnouncement, UpdateAnnouncement} from './announcements.actions';
import {filter, skipUntil, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AnnouncementService {
  readonly announcement$: Observable<AnnouncementModel>;

  get latest(): AnnouncementModel {
    return this.store.selectSnapshot(AnnouncementsState.latest);
  }

  constructor(private readonly store: Store) {
    this.announcement$ = this.store.select(AnnouncementsState.latest).pipe(
      tap(() => this.store.dispatch(new LoadLatestAnnouncement())),
      skipUntil(this.store.select(AnnouncementsState.loaded).pipe(filter(loaded => loaded)))
    );
  }

  load(force = false): void {
    this.store.dispatch(new LoadLatestAnnouncement(force));
  }

  reload(): void {
    this.load(true);
  }

  create(settings: AnnouncementModel): Observable<void> {
    return this.store.dispatch(new CreateAnnouncement(settings));
  }

  update(settings: AnnouncementModel): Observable<void> {
    return this.store.dispatch(new UpdateAnnouncement(settings));
  }
}
